import { CompletedAssessment } from "@/features/lifestyle-medicine-assessment/pages/overview/types.ts";
import { LifestyleMedicineAssessmentScore } from "@/features/lifestyle-medicine-assessment/components/lifestyle-medicine-assessment-score.tsx";
import { Button, Card, CardContent } from "@/components/ui";

type AssessmentHistoryProps = {
    assessments: CompletedAssessment[];
};

export function AssessmentHistoryTitle() {
    return <h2 className="text-2xl text-center font-thin">History</h2>;
}

export function AssessmentHistoryCard({ assessments }: AssessmentHistoryProps) {
    return (
        <Card>
            <CardContent className="p-0 py-6">
                <ul className="flex flex-col">
                    {assessments.map((assessment) => (
                        <li
                            key={assessment.id}
                            className="flex flex-row items-center px-6 py-2 min-h-20 hover:bg-accent transition-colors"
                        >
                            <div className="flex-1">
                                <LifestyleMedicineAssessmentScore
                                    score={assessment.scores.total}
                                />
                                <p className="text-muted-foreground">
                                    {new Date(
                                        assessment.created_at,
                                    ).toLocaleDateString()}
                                </p>
                            </div>
                            <div>
                                <Button variant="ghost" asChild>
                                    <a
                                        target="_blank"
                                        href={route(
                                            "lifestyle-medicine-assessment.show-results",
                                            { id: assessment.public_id },
                                        )}
                                    >
                                        View
                                    </a>
                                </Button>
                            </div>
                        </li>
                    ))}
                </ul>
            </CardContent>
        </Card>
    );
}
