import { FormEventHandler } from "react";
import { Head, Link, useForm } from "@inertiajs/react";
import { Form } from "@/components/ui/inertia-form.tsx";
import { ChevronLeft, ChevronRight } from "lucide-react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CardFooter,
} from "@/components/ui";
import { LifestyleMedicineAssessmentTitle } from "@/features/lifestyle-medicine-assessment/components/lifestyle-medicine-assessment-title.tsx";
import { PageProps, PaginatedResponse } from "@/types/app";
import { CompletedAssessment } from "@/features/lifestyle-medicine-assessment/pages/overview/types.ts";
import {
    AssessmentHistoryCard,
    AssessmentHistoryTitle,
} from "@/features/lifestyle-medicine-assessment/pages/overview/assessment-history.tsx";

export function OverviewPage({
    assessmentHistory,
}: PageProps<{
    assessmentHistory?: PaginatedResponse<CompletedAssessment[]>;
}>) {
    const { processing } = useForm();
    return (
        <div className="flex flex-col space-y-10">
            <Head title="Overview | Lifestyle Medicine Assessment" />
            <Card>
                <CardHeader>
                    <LifestyleMedicineAssessmentTitle />
                </CardHeader>
                <CardContent className="justify-center">
                    <div>
                        <p className="mb-2">
                            The Lifestyle Medicine Assessment consists of 21
                            questions covering 5 categories of modifiable
                            behaviors associated with positive health outcomes.
                        </p>
                        <p>
                            The assessment takes approximately 5 minutes to
                            complete.
                        </p>
                    </div>
                </CardContent>
                <CardFooter className="flex-col">
                    <div className="justify-center gap-2 mb-6">
                        <Button type="submit" disabled={processing} asChild>
                            <Link
                                href={route(
                                    "lifestyle-medicine-assessment.show-questions",
                                )}
                            >
                                Start
                            </Link>
                        </Button>
                    </div>
                    <p className="text-xs text-muted-foreground">
                        <span className="mr-1">
                            By taking this assessment you are agreeing to Apasas
                            Health's
                        </span>
                        <a
                            href="/terms-of-service"
                            className="text-primary underline font-bold mr-1"
                            target="_blank"
                        >
                            Terms of Service
                        </a>
                        <span className="mr-1">and</span>
                        <a
                            href="/privacy-policy"
                            className="text-primary underline font-bold"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                    </p>
                </CardFooter>
            </Card>
            {assessmentHistory && assessmentHistory.data?.length > 0 && (
                <div className="flex flex-col space-y-4">
                    <AssessmentHistoryTitle />
                    <AssessmentHistoryCard
                        assessments={assessmentHistory.data}
                    />
                    <div className="flex justify-center items-center space-x-1">
                        {assessmentHistory.prev_page_url && (
                            <Button
                                variant="outline"
                                disabled={processing}
                                asChild
                            >
                                <Link href={assessmentHistory.prev_page_url}>
                                    <ChevronLeft size={20} />
                                    Back
                                </Link>
                            </Button>
                        )}
                        {assessmentHistory.next_page_url && (
                            <Button
                                variant="outline"
                                disabled={processing}
                                asChild
                            >
                                <Link href={assessmentHistory.next_page_url}>
                                    Next
                                    <ChevronRight size={20} />
                                </Link>
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
